import { ArrowRightIcon } from "@heroicons/react/24/outline";
import type { Post } from "prisma-types";
import type { SerializeFrom } from "@remix-run/node";
import { Link } from "@remix-run/react";
import dayjs from "dayjs";
import { removeHtmlTags } from "~/utils/string.utils";

export function ArticleTile({ post }: { post: SerializeFrom<Post> }) {
  return (
    <div className="text-xs">
      <img
        src={
          post.cover_image_url || "/assets/article-software-survival-kit.png"
        }
        alt={post.name}
        className="rounded-lg"
        width="840"
        height="300"
      />
      <p className="text-gray-400 flex justify-between mt-2">
        <span>{dayjs(post.published_at).fromNow()}</span>
      </p>
      <p className="my-2">{post.name}</p>
      <p className="font-lg whitespace-normal line-clamp-3 truncate font-light">
        {removeHtmlTags(post.body || "...")}
      </p>
      <Link to={`/articles/${post.slug}`} className="block mt-2 text-sky-500">
        Read post <ArrowRightIcon className="h-4 inline" />
      </Link>
    </div>
  );
}
