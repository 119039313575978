import { ArrowRightIcon } from "@heroicons/react/20/solid";
import type { MetaFunction } from "@remix-run/node";
import { json } from "@remix-run/node";
import { Link, useLoaderData, useNavigate } from "@remix-run/react";
import { useState } from "react";
import { CTA } from "~/components/cta.component";
import ProductTile from "~/components/product-tile.component";
import { WebDIContainer } from "~/di-containers/web.di-container.server";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime.js";
import { AutocompleteSearchBar } from "~/components/discovery/autocomplete-search-bar.component";
import { ArticleTile } from "~/components/discovery/article-tile.component";
dayjs.extend(relativeTime);

export const meta: MetaFunction<typeof loader> = () => {
  return [
    { title: "Revyse | Multifamily software buying made easier" },
    {
      name: "description",
      content:
        "Search by product or category to discover the best multifamily proptech vendors, read reviews, and make better buying decisions. ",
    },
    {
      name: "og:image",
      property: "og:image",
      content: "/assets/open-graph-image.png",
    },
  ];
};

export async function loader() {
  const { db, circleService, productService } = await WebDIContainer();

  const categories = await db.productCategory.findMany({
    where: {
      highlighted: true,
    },
    take: 4,
  });

  const products: {
    [id: string]: Awaited<
      ReturnType<typeof productService.getProductsForPrimaryCategory>
    >;
  } = {};
  for (const cat of categories) {
    products[cat.id] = await productService.getProductsForPrimaryCategory({
      categoryId: cat.id,
      take: 8,
    });
  }

  const posts = await circleService.getPosts(4);

  const categoryIds = (
    await db.productCategory.findMany({
      select: { id: true },
    })
  ).map(p => p.id);

  const mostLovedProducts = await productService.getProductsMostLoved({
    take: 8,
    categories: categoryIds,
  });

  const tabs = [{ id: "Most Loved", name: "Most Loved" }, ...categories];

  products["Most Loved"] = mostLovedProducts;

  return json({
    tabs,
    products,
    posts,
  });
}

export default function HomeRoute() {
  const { tabs, products, posts } = useLoaderData<typeof loader>();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(tabs[0]);
  return (
    <>
      <style type="text/css">
        {`
        @media only screen and (min-width: 700px) {
          .hero-stripe {
            background-image: url(/assets/home-hero-illustrations.png);
            background-size: 110%
          } 
        }
        `}
      </style>
      <div className="bg-sky-500 flex justify-center md:py-20 py-12 px-6 bg-center bg-contain bg-no-repeat hero-stripe">
        <div className="max-w-3xl text-white text-center">
          <h1 className="font-bold text-3xl lg:text-6xl">
            Discover the top-rated <br className="hidden md:block" />
            multifamily vendors
          </h1>
          <p className="my-6 text-base leading-tight lg:text-lg text-white md:px-24">
            Search by product or category to discover the best software and
            services, read reviews, and make better buying decisions.
          </p>
          <AutocompleteSearchBar />
        </div>
      </div>
      <div className="flex justify-center md:py-12 py-6 bg-white">
        <div className="max-w-6xl flex-grow md:px-8 px-4 overflow-hidden">
          <h2 className="font-bold mx-3 text-2xl lg:text-3xl">
            Browse popular categories
          </h2>
          <div className="my-6 flex flex-wrap justify-between">
            <div className="flex text-sm md:text-base">
              {tabs.map((c, index) => (
                <button
                  key={c.id}
                  id={`category${index}`}
                  className={`border-b border-b-gray-300 text-gray-300 px-2 py-1 mx-2 ${
                    c.id === activeTab.id ? "text-sky-500 border-b-sky-500" : ""
                  }`}
                  onClick={() => setActiveTab(c)}
                >
                  {c.name}
                </button>
              ))}
            </div>
            <div className="flex-grow text-center md:text-right mt-4 md:mt-0">
              <Link
                className="text-sky-500"
                to={`/categories`}
                id="see-all-categories"
              >
                See all categories
              </Link>
            </div>
          </div>
          <div className="grid grid-cols-2 md:grid-cols-4 gap-2 p-2">
            {products[activeTab.id].map(p => (
              <ProductTile
                key={p.id}
                onClick={() => navigate(`/products/${p.slug}`)}
                product={{
                  name: p.title,
                  avgRating: p.avg_score,
                  totalRatings: p.cnt,
                  logo_file: { id: p.logo_file_id ?? p.vendor_logo_file_id },
                }}
              ></ProductTile>
            ))}
          </div>
        </div>
      </div>

      <style type="text/css">
        {`
          @media only screen and (min-width: 600px) {
            .features-stripe {
              background-image: url(/assets/product-screenshot.png);
              background-size: 105%;
              background-position: top left;
              background-repeat: no-repeat;
              height: 530px;
            } 
          }
        `}
      </style>
      <div className="flex justify-center md:pt-12 md:pb-0 py-8 bg-sky-50 bg-right bg-no-repeat ">
        <div className="max-w-6xl flex-grow md:px-11 px-6">
          <img
            src="/assets/product-screenshot.png"
            className="md:hidden mb-4"
            alt="screenshot"
            width="1283"
            height="906"
          />
          <h2 className="font-bold text-2xl lg:text-3xl">
            Meet the easiest way to discover new vendors
          </h2>
          <div className="flex my-8 lg:mt-16">
            <div className="md:w-1/3">
              <h4 className="font-medium">Reviews you can rely on</h4>
              <p className="mt-1 [text-wrap:pretty]">
                Get access to unbiased industry feedback and uncover the
                top-rated multifamily software and services with certainty.
              </p>

              <h4 className="mt-8 font-medium">Say hello to transparency</h4>
              <p className="mt-1 [text-wrap:pretty]">
                Compare features, pricing, and integrations to quickly
                understand if a new vendor is right for you, without the
                high-pressure sales pitch.
              </p>

              <h4 className="mt-8 font-medium">Made for multifamily</h4>
              <p className="mt-1 [text-wrap:pretty]">
                Purpose-built to help PMCs discover, buy, and manage software,
                Revyse connects the best tech with the teams who need it most.
              </p>

              <h4 className="mt-8 font-medium">You're in the driver's seat</h4>
              <p className="mt-1 [text-wrap:pretty]">
                Revyse puts you in control of the sales process. Explore
                products at your own pace, access demos on-demand, and negotiate
                pricing with ease.
              </p>
            </div>
            <div className="md:w-2/3 features-stripe"></div>
          </div>
        </div>
      </div>

      <div className="flex justify-center md:py-24 py-12 bg-white">
        <div className="flex max-w-3xl flex-grow flex-wrap justify-center px-8">
          <div className="flex-1">
            <h2 className="font-bold text-2xl lg:text-3xl">
              Join the conversation
            </h2>
            <p className="my-4">
              Make your voice heard in the industry and help the community tap
              into the power of reviews to evaluate proptech products like a
              pro.
            </p>
            <CTA fillStyle="outline" to="/categories" id="review-button">
              Write a Review
            </CTA>
          </div>
          <img
            className="md:max-h-48 max-h-24 w-auto"
            src="/assets/review-1.png"
            alt="review 1"
            height="1033"
            width="1872"
          ></img>
        </div>
      </div>

      <div className="flex justify-center md:py-24 py-12 bg-sky-50">
        <div className="flex flex-wrap justify-between max-w-5xl flex-grow px-6 relative">
          <div className="md:w-2/3 mb-6 md:mb-0">
            &quot;Innovation has exploded in multifamily, to the point that it's
            a full time job keeping pace with new vendors. Determining the very
            best providers to serve our residents is paramount. Revyse lets us
            explore categories and really do our homework - reaching out to
            vendors once we are educated and informed.&quot;
          </div>
          <div className="flex">
            <img
              src="/assets/laurel-zacher.webp"
              alt="profile pic"
              className="w-24 h-24 mr-4 rounded-full"
              height="300"
              width="300"
            />
            <div>
              <strong>Laurel Zacher</strong>
              <p className="text-gray-400 text-xs">
                VP Marketing + Customer Engagement <br />
                Security Properties Residential
              </p>
            </div>
          </div>
          <img
            src="/assets/cloud-2.png"
            className="absolute -left-32 -bottom-16 md:-bottom-28 h-24 hidden md:block"
            alt="cloud"
            height="70"
            width="169"
          />
          <img
            src="/assets/cloud-1.png"
            className="absolute -right-32 -top-32 h-24 w-auto hidden md:block"
            alt="cloud"
            height="206"
            width="414"
          />
        </div>
      </div>

      <div className="flex justify-center md:py-24 py-12 bg-white">
        <div className="flex flex-wrap max-w-3xl flex-grow px-6 justify-center">
          <img
            className="md:w-1/3 mr-12 max-h-48 md:max-h-none w-auto"
            src="/assets/vendor-illustration-2.png"
            alt="review 1"
            height="1405"
            width="1478"
          ></img>
          <div className="flex-1 mt-6 md:mt-0">
            <h2 className="font-bold text-2xl lg:text-3xl">
              B2B has shifted for good
            </h2>
            <p className="my-4">
              For today’s B2B software buyer, online research and reviews hold
              more sway than sales pitches and swag. Revyse connects vendors
              with verified in-market buyers, helping the best multifamily
              products and services get found.
            </p>
            <CTA
              to="/for-vendors"
              fillStyle="outline"
              className="inline-block"
              id="vendor-button"
            >
              Become a Vendor
            </CTA>
          </div>
        </div>
      </div>

      <div className="flex justify-center py-12 bg-sky-50">
        <div className="max-w-6xl flex-grow md:px-11 px-6">
          <div className="flex justify-between">
            <h2 className="font-bold text-2xl lg:text-3xl">Latest Articles</h2>
            <div>
              <Link
                className="w-max flex items-center text-sky-500 text-sm lg:text-base"
                to="/articles"
                id="posts-button"
              >
                See all posts <ArrowRightIcon className="ml-1 h-4 inline" />
              </Link>
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-4 gap-10 md:gap-4 py-4">
            {posts.map(p => (
              <ArticleTile post={p} key={p.id} />
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
